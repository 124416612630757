exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-music-index-tsx": () => import("./../../../src/pages/music/index.tsx" /* webpackChunkName: "component---src-pages-music-index-tsx" */),
  "component---src-pages-music-music-type-music-slug-tsx": () => import("./../../../src/pages/music/{Music.type}/{Music.slug}.tsx" /* webpackChunkName: "component---src-pages-music-music-type-music-slug-tsx" */),
  "component---src-pages-spotlight-tsx": () => import("./../../../src/pages/spotlight.tsx" /* webpackChunkName: "component---src-pages-spotlight-tsx" */)
}

